import Button from "components/core/Button";
import ModalWrap from "components/core/ModalWrap";
import { breakpointsMedias } from "configs/constants/breakpointMedias";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import imgNotiQC from "assets/images/img-tele-noti.png";

const Popup = () => {
  const { t } = useTranslation();
  const [show, setshow] = useState(false);

  useEffect(() => {
    let closedPopup = localStorage.getItem("popup")
    if (!closedPopup || closedPopup !== "closed") {
      setshow(true)
    }
  }, [])

  return show ? (
    <ModalWrap
      onClose={() => {
        setshow(false);
        // sessionStorage.setItem("popup", "closed")
      }}
    >
      <Wrap>
        <span className="size-5 mts-title text-center color-white">
          {t("whatsNew")}
        </span>
        <div className="popup-content">
          <div className="pc-text">
            <span className="size-3 color-white">{t("popupText1")}</span>
            <span className="size-1 color-gray">{t("popupText2")}</span>
            <a
              className="mt-auto"
              href="https://docs.tronsave.io/seller/sell-energy/sell-suggestion"
              target="_blank"
              onClick={() => {
                setshow(false);
              }}
            >
              <Button text="popupText3" />
            </a>
          </div>
          <div className="pc-img">
            <img src={imgNotiQC} alt="" />
          </div>
        </div>
        <span className="color-yellow text-skip size-2" onClick={() => {
          setshow(false);
          localStorage.setItem("popup", "closed")
        }}>{t("noShowAgain")}</span>
      </Wrap>
    </ModalWrap>
  ) : null;
};

export default Popup;
const Wrap = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  gap: 30px;
  .popup-content {
    display: flex;
    gap: 20px;
    width: 100%;
    .pc-text {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      
    }
    .pc-img {
      width: calc(50% - 10px);
      display: flex;
      height: fit-content;
      background: #000;
      > img {
        width: 100%;
        height: auto;
      }
    }
    a {
      margin-top: auto;
      width: 100%;
      max-width: 159px; 
      > button {
        width: 100%
      }
    }
  }
  .text-skip {
        cursor: pointer;
        text-decoration: underline;
        margin-top: -20px;
        &:hover {
          color: yellow;
        }
  }
  ${breakpointsMedias.max1199} {
  }
  ${breakpointsMedias.max767} {
    max-width: calc(100vw - 32px);
    .popup-content {
      flex-direction: column-reverse;
      align-items: center;
      .pc-text {
        align-items: center;
      }
      .pc-img {
        width: 100%;
        max-width: 175px;
      }
    }
  }
`;
